// Node Modules
import React from 'react'
import { graphql } from "gatsby"

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo'
import PageHeader from '../components/PageHeader'

const SuccessPage = ({ location, data }) => {
  const settings = data.contactsettings.childMarkdownRemark.frontmatter
  return (
    <>
      <Layout location={location}>
        <Seo />
        <PageHeader featureimage={data.contactsettings.childMarkdownRemark.featuredimageLocal} featureimagealt={settings.featuredimagealt} title={settings.settingsuccesstitle} opacity={true} />
        <section className="container mx-auto">
          <div className=" py-2 px-2 flex flex-col lg:flex-row">
            <div className="w-full lg:w-2/3 lg:mr-12">
              <div className="cmscontent" dangerouslySetInnerHTML={{ __html: data.contactsettings.childMarkdownRemark.html }} />
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default SuccessPage

export const pageQuery = graphql`
  query {
        contactsettings: file(relativePath: {eq: "settings/contact.md"}) {
        id
        childMarkdownRemark {
            html
            featuredimageLocal {
              childImageSharp {
                  gatsbyImageData(
                      placeholder: BLURRED,
                      formats: [AUTO, WEBP]
                      transformOptions: {fit: COVER, cropFocus: CENTER}
                  )
              }
              ext
              publicURL
          }
            frontmatter {
              featuredimagealt
              settingsuccesstitle
            }
        }
    }
  }
`